import axios from 'axios'
import { string } from 'yup'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class UserService {
  createUser (data) {
    return axios.post(API_URL + 'parent/users',
      {
        name: data.name,
        secret_code: data.secret_code,
        place_of_purchase: data.place_of_purchase,
        country: data.country,
        subscribe_to_news: data.subscribe_to_news
      },
      { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getBalance () {
    return axios.get(API_URL + 'parent/balance', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  viewLetterRequest (data) {
    return axios.put(API_URL + 'parent/users/view_letter_requests', {
      letter_requested: data.letter_requested
    }, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getUsers () {
    return axios.get(API_URL + 'parent/users', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getLetters () {
    return axios.get(API_URL + 'parent/users?letter_requested=true', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getCoinRefills () {
    return axios.get(API_URL + 'parent/coin_refills', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getMarks () {
    return axios.get(API_URL + 'parent/marks', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getPlaces () {
    return axios.get(API_URL + 'purchase_places', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getCountries () {
    return axios.get(API_URL + 'countries', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getCountryByIp () {
    return axios.get(API_URL + 'countries/by_ip', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  putUserFlag () {
    return axios.put(API_URL + 'user/flag', {}, { headers: authHeader() })
      .then(response => {
        return response
      })
  }
}

export default new UserService()
